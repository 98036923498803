<template>
    <section id="features">
        <h2>Key Features</h2>
        <div id="features-container" class="is-animated" :class="{'fade-childs': !preRendered,}">
            <div class="feature-item">
                <svg width="40" height="40" fill="currentColor" viewBox="0 0 96.666 96.666">
                    <rect x="36" y="6" width="24.666" height="24.666"></rect><rect x="36" y="66" width="24.666" height="24.666"></rect><rect y="66" width="24.666" height="24.666"></rect><rect x="72" y="66" width="24.666" height="24.666"></rect><polygon points="15.31,51.333 45.833,51.333 45.833,62.666 50.833,62.666 50.833,51.333 82.105,51.333 82.105,63.066  86.561,63.066 86.561,46.4 84.334,46.4 84.334,46.333 50.833,46.333 50.833,35 45.833,35 45.833,46.333 12.334,46.333 12.334,46.4  10.856,46.4 10.856,63.066 15.31,63.066  "></polygon>
                </svg>
                <div class="title">BlockDAG</div>
                <div class="description">Mutliple blocks at the same height to improve scalability and security of the network.</div>
            </div>
            <div class="feature-item">
                <svg width="40" height="40" viewBox="0 0 16 16">
                    <path d="m 8 1 c -2.199219 0 -4 1.800781 -4 4 v 2 c -1.109375 0 -2 0.890625 -2 2 v 5 c 0 0.554688 0.445312 1 1 1 h 10 c 0.554688 0 1 -0.445312 1 -1 v -5 c 0 -1.109375 -0.890625 -2 -2 -2 v -2 c 0 -2.199219 -1.800781 -4 -4 -4 z m 0 2 c 1.125 0 2 0.875 2 2 v 2 h -4 v -2 c 0 -1.125 0.875 -2 2 -2 z m 0 0" fill="currentColor"></path>
                </svg>
                <div class="title">Homomorphic Encryption</div>
                <div class="description">Enabled using ElGamal to provide privacy on transferred amounts and balances.</div>
            </div>
            <div class="feature-item">
                <svg width="35" height="35" viewBox="0 0 16 16" fill="none">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M15 1H1V15H15V1ZM6 5L7.41421 6.41421L5.82843 8L7.41421 9.58579L6 11L3 8L6 5ZM10 5L8.58579 6.41421L10.1716 8L8.58579 9.58579L10 11L13 8L10 5Z" fill="currentColor"></path>
                </svg>
                <div class="title">Smart Contracts</div>
                <div class="description">Easily code and create decentralized programs.</div>
            </div>
            <div class="feature-item">
                <svg width="30" height="30" viewBox="-2 0 32 32" fill="currentColor">
                    <path id="Path_62" data-name="Path 62" d="M28,0H6A4,4,0,0,0,2,4V28a4,4,0,0,0,4,4H28a2,2,0,0,0,2-2V2A2,2,0,0,0,28,0ZM26,27a1,1,0,0,1-1,1H7.98A1.979,1.979,0,0,1,6,26.02v-.04A1.979,1.979,0,0,1,7.98,24H25a1,1,0,0,1,1,1Zm0-7.937a1,1,0,0,1-1,1H11a1,1,0,0,1-1-1V5a1,1,0,0,1,1-1H25a1,1,0,0,1,1,1Z"/>
                    <rect id="Rectangle_19" data-name="Rectangle 19" width="8" height="4" rx="1" transform="translate(14 8)"/>
                </svg>
                <div class="title">Account Model</div>
                <div class="description">Account based blockchain with more advantages than traditional UTXO.</div>
            </div>
            <div class="feature-item">
                <svg width="35" height="35" viewBox="0 0 512 512" fill="currentColor">
                    <polygon points="386.415,193.208 287.481,193.208 359.434,0 161.566,0 125.585,280.151 206.528,280.151 170.557,512"/>
                </svg>
                <div class="title">Boost & Fast Synchronization</div>
                <div class="description">Synchronize in parallel across multiple nodes or instantly with the latest chain state.</div>
            </div>
            <div class="feature-item">
                <svg width="40" height="40" fill="currentColor" viewBox="0 0 32 32">
                    <path d="M21.022 7.57l-1.085 0.672-0.638 4.16-0.001 0.002 2.392-3.692zM17.176 13.044l-4.91-1.623-1.362 0.841 0.897 1.080zM12.916 4.884c0.199-0.111 0.367-0.269 0.483-0.471 0.404-0.701 0.048-1.664-0.796-2.151s-1.856-0.314-2.26 0.386c-0.117 0.202-0.17 0.426-0.166 0.655-2.892-1.297-6.034-1.598-8.557-0.37 6.565 1.888 12.005 5.305 16.746 9.674-0.665-3.046-2.783-5.805-5.451-7.723zM8.636 6.416l-7.023 12.172c0.521 0.857 1.287 1.552 2.592 1.498l7.023-12.172c-0.798-0.574-1.666-1.068-2.592-1.498zM25.291 12.093l-11.3 3.489-2.641 5.171 13.511 9.812 5.526-15.689z"></path>
                </svg>
                <div class="title">XELIS Hash</div>
                <div class="description">A new CPU/GPU friendly algorithm. FPGAs and ASIC resistance.</div>
            </div>
            <div class="feature-item">
                <svg width="40" height="40" viewBox="0 0 24 24" fill="none">
                    <path d="M17.8321 9.5547C18.1384 9.09517 18.0142 8.4743 17.5547 8.16795C17.0952 7.8616 16.4743 7.98577 16.168 8.4453L13.3925 12.6085L10.0529 10.3542C9.421 9.92768 8.55941 10.1339 8.18917 10.8004L6.12584 14.5144C5.85763 14.9971 6.03157 15.6059 6.51436 15.8742C6.99714 16.1424 7.60594 15.9684 7.87416 15.4856L9.56672 12.439L12.8571 14.66C13.4546 15.0634 14.2662 14.9035 14.6661 14.3036L17.8321 9.5547Z" fill="currentColor"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M7 2C4.23858 2 2 4.23858 2 7V17C2 19.7614 4.23858 22 7 22H17C19.7614 22 22 19.7614 22 17V7C22 4.23858 19.7614 2 17 2H7ZM4 7C4 5.34315 5.34315 4 7 4H17C18.6569 4 20 5.34315 20 7V17C20 18.6569 18.6569 20 17 20H7C5.34315 20 4 18.6569 4 17V7Z" fill="currentColor"></path>
                </svg>
                <div class="title">Difficulty Adjustment</div>
                <div class="description">Using Kalman Filter to quickly converge network hashrate and filter misleading fluctuations.</div>
            </div>
            <div class="feature-item">
                <svg width="40" height="40" viewBox="0 0 24 24" fill="none">
                    <path d="M9 10.5L11 12.5L15.5 8M20 21V7.8C20 6.11984 20 5.27976 19.673 4.63803C19.3854 4.07354 18.9265 3.6146 18.362 3.32698C17.7202 3 16.8802 3 15.2 3H8.8C7.11984 3 6.27976 3 5.63803 3.32698C5.07354 3.6146 4.6146 4.07354 4.32698 4.63803C4 5.27976 4 6.11984 4 7.8V21L6.75 19L9.25 21L12 19L14.75 21L17.25 19L20 21Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                </svg>
                <div class="title">Proofs</div>
                <div class="description">ZK Proof, ownership proof and balance proof.</div>
            </div>
            <div class="feature-item">
                <svg width="40" height="40" viewBox="0 0 24 24" fill="none">
                    <path stroke="currentColor" stroke-width="2" d="M10 15.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0Zm9 0a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0Z"></path>
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m10 15 .211-.106a4 4 0 0 1 3.578 0L14 15m3-6-1.65-4.125a1 1 0 0 0-1.245-.577l-1.473.491a2 2 0 0 1-1.264 0L9.894 4.3a1 1 0 0 0-1.245.576L7 9m-4 1c7.5-1 11-1 18 0"></path>
                </svg>
                <div class="title">Confidential Assets</div>
                <div class="description">Create your own native token fully compatible with all services.</div>
            </div>
            <div class="feature-item">
                <svg width="40" height="40" viewBox="0 0 24 24" fill="currentColor">
                    <path d="M13.4822 17.7322L12.7071 16.9571L15.1893 14.4749C16.5562 13.108 16.5562 10.8919 15.1893 9.52511L12.7071 7.04288L13.4822 6.26776C14.4585 5.29144 16.0414 5.29144 17.0178 6.26776L20.9822 10.2322C21.9585 11.2085 21.9585 12.7914 20.9822 13.7678L17.0178 17.7322C16.0414 18.7085 14.4585 18.7085 13.4822 17.7322Z"></path><path d="M14.4822 13.7678C15.4585 12.7914 15.4585 11.2085 14.4822 10.2322L10.5178 6.26776C9.54144 5.29144 7.95853 5.29145 6.98222 6.26776L3.01776 10.2322C2.04144 11.2085 2.04145 12.7914 3.01776 13.7678L6.98222 17.7322C7.95853 18.7085 9.54144 18.7085 10.5178 17.7322L14.4822 13.7678Z"></path>
                </svg>
                <div class="title">Client Protocol</div>
                <div class="description">Allows accepting same transaction in multiple blocks without double spending.</div>
            </div>
            <div class="feature-item">
                <svg width="50" height="50" viewBox="0 0 24 24" fill="none">
                    <path d="M13.5 7L17 10.5" stroke="currentColor" stroke-linecap="round"></path><path d="M7 13.5L10.5 17" stroke="currentColor" stroke-linecap="round"></path><path d="M10.5 7L7 10.5" stroke="currentColor" stroke-linecap="round"></path><path d="M17 13.5L13.5 17" stroke="currentColor" stroke-linecap="round"></path><circle cx="12" cy="5.5" r="2" stroke="currentColor"></circle><circle cx="12" cy="18.5" r="2" stroke="currentColor"></circle><circle cx="5.5" cy="12" r="2" stroke="currentColor"></circle><circle cx="18.5" cy="12" r="2" stroke="currentColor"></circle><circle cx="5.5" cy="12" r="0.5" stroke="currentColor"></circle><circle cx="12" cy="18.5" r="0.5" stroke="currentColor"></circle><circle cx="18.5" cy="12" r="0.5" stroke="currentColor"></circle>
                </svg>
                <div class="title">P2P Encrypted Network</div>
                <div class="description">Prevent any network traffic analysis and provide better privacy.</div>
            </div>
            <div class="feature-item">
                <svg width="40" height="40" viewBox="0 0 24 24" fill="none">
                    <path d="M13.5295 8.35186C12.9571 8.75995 12.2566 9 11.5 9C9.567 9 8 7.433 8 5.5C8 3.567 9.567 2 11.5 2C12.753 2 13.8522 2.65842 14.4705 3.64814M6 20.0872H8.61029C8.95063 20.0872 9.28888 20.1277 9.61881 20.2086L12.3769 20.8789C12.9753 21.0247 13.5988 21.0388 14.2035 20.9214L17.253 20.3281C18.0585 20.1712 18.7996 19.7854 19.3803 19.2205L21.5379 17.1217C22.154 16.5234 22.154 15.5524 21.5379 14.9531C20.9832 14.4134 20.1047 14.3527 19.4771 14.8103L16.9626 16.6449C16.6025 16.9081 16.1643 17.0498 15.7137 17.0498H13.2855L14.8311 17.0498C15.7022 17.0498 16.4079 16.3633 16.4079 15.5159V15.2091C16.4079 14.5055 15.9156 13.892 15.2141 13.7219L12.8286 13.1417C12.4404 13.0476 12.0428 13 11.6431 13C10.6783 13 8.93189 13.7988 8.93189 13.7988L6 15.0249M20 6.5C20 8.433 18.433 10 16.5 10C14.567 10 13 8.433 13 6.5C13 4.567 14.567 3 16.5 3C18.433 3 20 4.567 20 6.5ZM2 14.6L2 20.4C2 20.9601 2 21.2401 2.10899 21.454C2.20487 21.6422 2.35785 21.7951 2.54601 21.891C2.75992 22 3.03995 22 3.6 22H4.4C4.96005 22 5.24008 22 5.45399 21.891C5.64215 21.7951 5.79513 21.6422 5.89101 21.454C6 21.2401 6 20.9601 6 20.4V14.6C6 14.0399 6 13.7599 5.89101 13.546C5.79513 13.3578 5.64215 13.2049 5.45399 13.109C5.24008 13 4.96005 13 4.4 13L3.6 13C3.03995 13 2.75992 13 2.54601 13.109C2.35785 13.2049 2.20487 13.3578 2.10899 13.546C2 13.7599 2 14.0399 2 14.6Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                </svg>
                <div class="title">Transaction Fees</div>
                <div class="description">Strict fees to avoid blockchain abuse.</div>
            </div>
            <div class="feature-item">
                <svg width="30" height="30" viewBox="0 0 32 32" fill="currentColor">
                    <path d="M31.989 9.078c0.015-0.739-0.184-2.464-2.433-3.064l-22.576-4.519c-1.655 0-3 1.345-3 3v4.022l-1-0.002c-1.649 0.007-2.989 1.348-2.989 2.999v15.994c0 1.654 1.345 3 3 3h26.014c1.654 0 3-1.346 3-3zM5.981 4.494c0-0.522 0.402-0.952 0.913-0.996l22.063 4.465c0.008 0.004-0.164 0.56-0.965 0.55h-22.011zM30.008 27.507c0 0.552-0.448 1-1 1h-26.015c-0.552 0-1-0.448-1-1v-15.995c0-0.552 0.448-1 1-1h25.002c0.982 0 2.012-0.335 2.012-0.996v17.991h0zM5.995 17.516c-1.104 0-2 0.895-2 2s0.896 2 2 2 2-0.895 2-2-0.896-2-2-2z"></path>
                </svg>
                <div class="title">Wallet & Tools</div>
                <div class="description">Desktop & Mobile wallet with XSWD, Integrated Address, Encrypted Storage, MultiSig and more.</div>
            </div>
        </div>
    </section>
</template>

<style lang="scss">
@use "@/assets/variables.scss" as var;

#features {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;

    h2 {
        margin-bottom: 7rem;
    }

    #features-container {
        display: grid;
        gap: 2rem;
        width: 100%;
        grid-template-columns: repeat(auto-fill, minmax(30rem, 1fr));

        .feature-item {
            position: relative;
            display: flex;
            flex-direction: column;
            backdrop-filter: blur(6px);
            background: rgba(0, 0, 0, 0.3);
            border-radius: 1.5rem;
            padding: 3rem;

            overflow: hidden;

            .title {
                font-size: 3rem;
                margin-bottom: 1rem;
                font-weight: bold;
                margin-right: 4rem;
            }

            .description {
                font-weight: lighter;
                opacity: .8;
            }

            svg {
                position: absolute;
                z-index: -1;
                opacity: .2;
                color: var.$ascent-color;
                top: 2rem; 
                right: 2rem;
            }
        }
    }
}
</style>